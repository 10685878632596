import React, { useState } from 'react'

import "./../styles/components/hamburger.css";

export const useHamburgerActiveState = () => {
    const [isDroppedDown, setDroppedDown] = useState(false);

    return [isDroppedDown, setDroppedDown]
}

const Hamburger = ({
    height,
    hamburgerActiveState
}) => {

    const [isDroppedDown, setDroppedDown] = hamburgerActiveState;

    return (
        <button className={`hamburger ${isDroppedDown && "active"}`} style={{height: height + "px"}} onClick={() => {setDroppedDown(!isDroppedDown)}}>
            <div className='line line-1'></div>
            <div className='line line-2'></div>
            <div className='line line-3'></div>
        </button>
    )
}

export default Hamburger