import React from 'react';
import Product_Manage from "./Product_Manage";
import Assisted_Purchasing from './Assisted_Purchasing';
import Product_approve from './Product_approve';
import '../styles/navi.css';
import { Link ,Switch} from 'react-router-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Unit from './Unit';

const Product = () => {
  return (
    <> 
      <Unit 
        title={"Discover the best software for your business"}
        body={"Discover what SaaS is best for you and your team based on insights from thousands of software transactions."}
        titleColor={"black"}
        bodyColor={"black"}
        background={"linear-gradient(to right,#4AECFF,#FFDF10)"}
        imageSrc={process.env.PUBLIC_URL + "/images/S 2.png"}
      />

      <Unit 
        title={"Compare SaaS products on multiple parameters for the right purchase"}
        body={"Evaluate SaaS products based upon Pricing, Implementation time, Compatibility, Usage data from ProcSaaS Community."}
        titleColor={"#FF9A26"}
        bodyColor={"white"}
        background={"linear-gradient(to right,#0D464A,#0D464A)"}
        imageSrc={process.env.PUBLIC_URL + "/images/S 4.png"}
      /> 

      <Unit 
        title={"Check compatibility of SaaS products with your existing tech stack"}
        body={"Choose the right SaaS product"}
        titleColor={"black"}
        bodyColor={"black"}
        background={"white"}
        imageSrc={process.env.PUBLIC_URL + "/images/S 3.png"}
      /> 
</>



  );
};

export default Product;
