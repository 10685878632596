import React from 'react';
import '../styles/navi.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Unit from './Unit';

const Product_Manage = () => {
  return (
   <>
    <Unit 
        title={"Manage All Your SAAS Contracts From A Single Dashboard"}
        body={"Track your start and end dates, owner, cost, status and much more."}
        titleColor={"white"}
        bodyColor={"white"}
        background={"linear-gradient(to right,#1929B1,#E224FF)"}
        imageSrc={process.env.PUBLIC_URL + "/images/WhatsApp Image 2023-06-13 at 10.05.07 PM.png"}
    /> 

    <Unit 
        title={"Manage All Your SAAS Contracts From A Single Dashboard"}
        body={"Track your start and end dates, owner, cost, status and much more."}
        titleColor={"black"}
        bodyColor={"black"}
        background={"linear-gradient(to right,#29F144,#29F144)"}
        imageSrc={process.env.PUBLIC_URL + "/images/S 400x.png"}
    /> 
    
   </>
  )
}

export default Product_Manage;