import React from 'react';
import "./../styles/components/navbar.css";
import Hamburger, {useHamburgerActiveState} from './Hamburger';

import Home from './Home';

const Navbar = () => {

  const hamburgerActiveState = useHamburgerActiveState(false);

  return ( 
    <>
      <nav id='navbar'>
        <div className='logo-wrapper'>
          <a href = "/">
            <img src={process.env.PUBLIC_URL + "/images/ProcSaa$Artboard 2.png"} />
            <img src={process.env.PUBLIC_URL + "/images/ProcSaa$Artboard 1.png"} />
          </a>
        </div>
        <div className='nav-items-wrapper'>
          <a href = "/">Home</a>
          <a href = "/product">Platform</a>
          <a href = "/product_manage">Manage</a>
          <a href = "/product_approve">Approved</a>
          <a href = "/assisted_purchasing">Assisted Purchasing</a>
        </div>
        <div className='hamburger-wrapper'>
          <Hamburger height={60} hamburgerActiveState={hamburgerActiveState}/>
          <div className={`hamburger-list ${!hamburgerActiveState[0] && "closed"}`}>
            {/* {hamburgerActiveState ? "true" : "false"} */}
            <a href = "/"><i className='far fa-home'></i>Home</a>
            <a href = "/product"><i className='far fa-stairs'></i>Platform</a>
            <a href = "/product_manage"><i className='far fa-list-check'></i>Manage</a>
            <a href = "/product_approve"><i className='far fa-badge-check'></i>Approved</a>
            <a href = "/assisted_purchasing"><i className='far fa-handshake'></i>Assisted Purchasing</a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
