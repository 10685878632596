import React from 'react';

import "./../styles/components/unit.css";

const Unit = ({
    background,
    titleColor,
    bodyColor,
    title,
    body,
    imageSrc
}) => {
  return (
    <section className='unit' style={{
        "--title-color": titleColor,
        "--body-color": bodyColor,
        "--background": background
    }}>
        <div className='left'>
            <h1 className='title'>
                {title}
            </h1>
            <article className='body'>
                {body}
            </article>
        </div>
        <div className='right'>
            <img src={imageSrc} alt='image' />
        </div>
    </section>
  )
}

export default Unit